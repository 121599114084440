import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { wrap } from '@popmotion/popcorn';
import useKeyPress from '../hooks/useKeyPress';

const variants = {
  enter: direction => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const Carousel = ({ getSlideIndex, images, initialSlide }) => {
  const [[slide, direction], setSlide] = useState([initialSlide || 0, 0]);
  const imageIndex = wrap(0, images.length, slide);

  const paginate = newDirection => {
    const newSlide = wrap(0, images.length, slide + newDirection);
    setSlide([newSlide, newDirection]);
    getSlideIndex(newSlide);
  };

  useKeyPress('ArrowLeft', () => paginate(-1));
  useKeyPress('ArrowRight', () => paginate(1));

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <Slide
          key={slide}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <img src={images[imageIndex]} alt={imageIndex} />
        </Slide>
      </AnimatePresence>
      <Button style={{ left: 0 }} onClick={() => paginate(-1)}>
        <svg height="32" width="32">
          <polyline points="18,10 12,16 18,22" />
        </svg>
      </Button>
      <Button style={{ right: 0 }} onClick={() => paginate(1)}>
        <svg height="32" width="32">
          <polyline points="14,10 20,16 14,22" />
        </svg>
      </Button>
    </>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export default Carousel;

const Slide = styled(motion.div)`
  height: 100%;
  width: 100%;
  padding: 5rem 0;
  outline: none;
  position: absolute;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 500px) {
    padding: 5rem;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
`;

const Button = styled.button`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  opacity: 0.5;
  transition: all 300ms;
  margin: 0 1.5rem;
  cursor: pointer;
  display: none;
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 500px) {
    display: block;
  }

  &:hover {
    background: #f8f3f3;
    opacity: 1;
  }

  svg {
    display: block;
    fill: none;
    stroke: #6e514c;
    stroke-width: 1.5;
  }
`;
