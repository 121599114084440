import React, { useState } from 'react';
import { graphql } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';

import Carousel from '../../components/Carousel';
import Layout from '../../components/folioLayout';
import { Caption } from '../../styles/Typography';

const Portfolio = ({ data, location }) => {
  const { images } = data.markdownRemark.frontmatter;
  const initialSlide = location?.state?.slideIndex || 0;
  const [slideIndex, setSlideIndex] = useState(initialSlide);

  console.log(location.state);
  return (
    <Layout>
      <Container>
        <Carousel
          images={images}
          initialSlide={initialSlide}
          getSlideIndex={i => setSlideIndex(i)}
        />
        <Footer>
          <Caption style={{ opacity: 0.5 }}>
            {slideIndex + 1} of {images.length}
          </Caption>
          <ThumbnailButton to="/portfolio/thumbnails">
            <Caption>Thumbnail view</Caption>
            <svg height="16" width="16">
              <rect x="0" y="0" width="5" height="7" />
              <rect x="7" y="0" width="9" height="7" />
              <rect x="0" y="9" width="8" height="7" />
              <rect x="10" y="9" width="6" height="7" />
            </svg>
          </ThumbnailButton>
        </Footer>
      </Container>
    </Layout>
  );
};

export default Portfolio;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 1.5rem 0 2rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

const ThumbnailButton = styled(TransitionLink)`
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  transition: all 300ms;
  border: none;
  z-index: 1000;

  svg {
    display: block;
    margin-left: 0.5rem;
    fill: #6e514c;
    opacity: 0.6;
  }

  &:hover {
    background: #f8f3f3;
    opacity: 1;
  }
`;

export const query = graphql`
  {
    markdownRemark(frontmatter: { title: { eq: "portfolio" } }) {
      frontmatter {
        images
      }
    }
  }
`;
